<template>
    <div class="aggregation">
        <div>{{$t('withdraw1.paragraph1')}}</div>
        <div class="swath_">
            <!-- <div class="swath_to">{{$t('nfts.text7')}}</div> -->
            <el-radio-group v-model="switchParms" size="large" @change="changeFun_">
                <el-radio-button :label="0">{{$t('nfts.text8')}}</el-radio-button>
                <el-radio-button :label="1">{{$t('nfts.text9')}}</el-radio-button>
            </el-radio-group>
        </div>
        <div>{{$t('currencySetNft.name2')}}</div>
        <div class="main_">
            <div v-for='item in appList' :key='item.appId' class="input_">
                <el-input v-model="item.appName" disabled class="input_1"/>
                <el-radio-group v-model="item.isAudit" size="large" @change="changeFun_(item)">
                    <el-radio-button :label="0">{{$t('nfts.text8')}}</el-radio-button>
                    <el-radio-button :label="1">{{$t('nfts.text9')}}</el-radio-button>
                </el-radio-group>
            </div>
        </div>
        <!-- <div>{{$t('nfts.text10')}}</div> -->
        <!-- <el-row :gutter="20" justify="end">
            <el-col :span="6" >
                <el-button @click="emit('successFun')">{{$t('button.closeQ')}}</el-button>
                <el-button type="primary" @click="emit('successFun')">{{$t('button.compla')}}</el-button>
            </el-col>
        </el-row> -->
    </div>
</template>
<script setup>
    import { ref,getCurrentInstance,defineExpose } from 'vue'
    import apiList from '@/const/apiList'
    // import { userStore } from '@/store/user'
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    const { $t } = getCurrentInstance().proxy;

    const appList = ref([])
    const switchParms = ref(0)
    // const emit = defineEmits(['successFun'])

    const updateFun = (e,black)=>{
        Api_.updateApp_1({
            appId: e.appId,
            isAudit: e.isAudit
        }).then(res=>{
            if(res.code === 0){
                ElNotification({
                    message: $t('alert.succBind'),
                    type: 'success',
                })
                black()
            }else{
                ElNotification({
                    message: res.msg,
                    type: 'error',
                })
                e.isAudit = !e.isAudit
            }
        })
    }
    const changeFun_ = (e)=>{
        if(e && e !== 1){
            updateFun(e,()=>{ getAppSwitch() })
        }else{
            for(let i = 0; i < appList.value.length; ++ i){
                if(switchParms.value !== appList.value[i].isAudit){
                    updateFun({
                        appId: appList.value[i].appId,
                        isAudit: switchParms.value,
                    },()=>{ getAppSwitch() })
                }
            }
        }
    }
    const getAppSwitch = ()=>{//获得当前商户下的app是否有打开归集
        apiList.getAppList((data)=>{
            appList.value = data
            for(let item of data){
                if(item['isAudit'] === 0){
                    switchParms.value = 0
                    return 0
                }
            }
            switchParms.value = 1
        })
    }
    const init_ = ()=>{
        getAppSwitch()
    }
    defineExpose({init_})
</script>

<style lang="scss" scoped>
    .aggregation{
        font-size: 13px;
        line-height: 20px;
        padding-top: -10px;
        .swath_{
            margin-top: 20px;
            margin-bottom: 10px;
            font-size: 15px;
            display: flex;align-items: center;
            &to{
                margin-right: 10px;
            }
        }
        .input_{
            display: flex;align-items: center;
            margin-bottom: 10px;
            &1{
                width: 200px;margin-right:30px
            }
        }
        .main_{
            height: 260px;
            overflow: auto;
        }
    }
</style>