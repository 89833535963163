import Api_ from '@/api/index'
import { ElNotification } from 'element-plus'

const getTenantList = (balk)=>{//获得用户的列表
    Api_.shopList().then(res=>{
        if(res.code === 0){
            balk(res.data.records)
        }else{
            ElNotification({
                message: res.msg,
                type: 'error',
            })
        }
    })
}
const getAppList = (balk,id)=>{//获得APP的列表
    let obj_ = {}
    if(id){obj_.tenantId = id}
    Api_.getUserappinfoList(obj_).then(res=>{
        if(res.code === 0){
            balk(res.data.records)
        }else{
            ElNotification({
                message: res.msg,
                type: 'error',
            })
        }
    })
}
const getChainList = (balk)=>{//获取链的列表
    Api_.getCoinOrxie({protocolType: 1,
        status: 1}).then(res=>{
        if(res.code === 0){
            balk(dicFormatter(res.data))
        }else{
            ElNotification({
                message: res.msg,
                type: 'error',
            })
        }
    })
}
const getHotWallet = (balk,parm)=>{//获得nft的出入境热钱包
    Api_.getAssetswalletinfoList(parm).then(res=>{
        if(res.code === 0){
            balk(res.data.records)
        }else{
            ElNotification({
                message: res.msg,
                type: 'error',
            })
        }
    })
}
const getNftSeries = (balk,parm)=>{//获得nft的系列
    Api_.getNftcollectioninfoList(parm).then(res=>{
        if(res.code === 0){
            balk(res.data.records)
        }else{
            ElNotification({
                message: res.msg,
                type: 'error',
            })
        }
    })
}
const dicFormatter = (e)=>{
    let obj_1 = []
    for(let item of e){
        if(item){
            let obj_ = {
                label: item.chain,
                value: item.chain,
            }
            if(item.chainProtocolInfos.length > 0){
                let obj_c = []
                for(let items of item.chainProtocolInfos){
                    obj_c.push({
                        label: items.protocol,
                        value: items.protocol,
                    })
                }
                obj_.children = obj_c
                obj_1.push(obj_)
            }
        }
    }
    return obj_1
}
export default{
    getTenantList,getAppList,getChainList,getNftSeries,
    getHotWallet
}