import il8n from '@/assets/language/index.js';
const { t } = il8n.global;

export const object = [{
    type: 'select',
    name: t('currencySet.name1'),
    prop: 'tenantId',
    isPostApi: 'shopList',
    postURl: '',
    postData: [],
    isPlatform: 1,
    props:{
        value:'id',
        label:'name'
    }
},{
    type: 'select',
    name: t('currencySet.name2'),
    prop: 'appId',
    isPostApi: 'getUserappinfoList',
    postURl: '',
    postData: [],
    props:{
        value:'appId',
        label:'appName'
    }
},{
    type: 'select',
    name: t('currencySet.text10'),
    prop: 'chain',
    isPostApi: 'getCoinList',
    postURl: '',
    postData: [],
    props:{
        value:'chain',
        label:'chain'
    }
},{
    type: 'select',
    name: t('currencySet.text11'),
    prop: 'protocol',
    isPostApi: 'getAgreementList',
    postURl: '',
    postData: [],
    props:{
        value:'protocol',
        label:'protocol'
    }
},{
    type: 'select',
    name: t('currencySet.text1'),
    prop: 'coin',
    isPostApi: 'getCurrencySetList',
    postURl: '',
    postData: [],
    props:{
        value:'tenantCoinId',
        label:'coin'
    }
},{
    type: 'input',
    name: t('otherWallat.name1'),
    prop: 'walletName',
},{
    type: 'input',
    name: t('withdraw.name4'),
    prop: 'applyId',
},{
    type: 'input',
    name: t('withdraw.name9'),
    prop: 'auditUser',
},{
    type: 'input',
    name: t('withdraw.name8'),
    prop: 'applyUser',
},{
    type: 'select',
    name: '提现类型',
    prop: 'withdrawType',
    postData: [{ label: '待选择', value: 0 },{label: '热提',value:1},{label: '冷提',value:2}],
},{
    type: 'datetime',
    name: t('withdraw.name11'),
    prop: 'applyTime',
},{
    type: 'select',
    name: t('currencySet.text12'),
    prop: 'status',
    postData: [
        { label: t('withdraw.name12_1'), value: 0 },
        {label: t('withdraw.name12_2'),value:1},
        {label: t('withdraw.name12_3'),value:2},
        {label: '待录入',value:4},
        {label: '录入成功',value:5}],
}]