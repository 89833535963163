import il8n from '@/assets/language/index.js';
const { t } = il8n.global;

import { walletStyle } from '@/utils/record'
export const tableOption1 = ()=> {
    return {
        border: true,
        stripe: true,
        menuAlign: 'center',
        searchMenuSpan: 6,
        editBtn: false,
        delBtn: false,
        align: 'center',
        addBtn: false,
        viewBtn: false,
        height: 'auto',
        refreshBtn: false,
        columnBtn: false,
        menu:false,
        column: [{
            width:100,
            label: t('withdraw.name4'),
            prop: 'applyId'
        }, {
            width:100,
            label: t('currencySet.name1'),
            prop: 'tenantName'
        }, 
        {
            width:100,
            label: t('currencySet.name2'),
            prop: 'appName',
        },
        {
            width:150,
            label: t('currencySet.name3'),
            prop: 'chain_protocol_coin',
        },
        {
            width:100,
            label: t('otherWallat.name1'),
            prop: 'fromWalletName',
        },
        {
            width:100,
            label: t('otherWallat.text6'),
            prop: 'fromAddress',
        },{
            width:120,
            label: t('withdraw.name5'),
            prop: 'fromWalletType',
            dicData: walletStyle
        },{
            width:150,
            label: t('withdraw.name3'),
            prop: 'amount',
        },{
            width:100,
            label: t('withdraw.name6'),
            prop: 'txFee',
        },{
            width:150,
            label: t('withdraw.name7'),
            prop: 'toAddress'
        },{
            width:150,
            label: t('withdraw.name8'),
            prop: 'applyUser',
        },{
            width:170,
            label: t('withdraw.name9'),
            prop: 'auditUser',
        },{
            width:150,
            label: t('withdraw.name2'),
            prop: 'applyReason',
        },{
            width:150,
            label: t('withdraw.name10'),
            prop: 'auditReason',
        },
        {
            width:170,
            label: t('withdraw.name11'),
            prop: 'applyTime',
        }, 
        {
            width:170,
            label: '提现类型',
            prop: 'withdrawType',
            dicData: [{ label: '--', value: 0 },{label: '热提',value:1},{label: '冷提',value:2}],
        },
        {
            width:170,
            label: t('withdraw.name12'),
            prop: 'auditTime',
        },{
            width:170,
            label: t('currencySet.text12'),
            prop: 'status',
            dicData: [{ label: t('withdraw.name12_1'), value: 0 },{label: t('withdraw.name12_2'),value:1},{label: t('withdraw.name12_3'),value:2},
            {label: '待录入',value:4},{label: '录入成功',value:5}],
        },{
            width: 140,
            label: t("button.operation"),
            prop: 'caozuo',
            placeholder: t("inputTit.shuru"),
            fixed:'right',
          }]
    }
}